<template>
  <div class="prize-saver">

    <b-col cols="12" md="5" class="d-block mx-auto my-0 p-0 pl-md-2 mb-3 float-md-right">
     <b-img fluid rounded src="/images/prize-saver.jpg" alt="Prize Saver" />
    </b-col>

    <h1>Saving feels good! Winning feels great!</h1>

    <b-link to="/savings/prize-saver/draws" class="btn btn-success my-3">View PrizeSaver Draw Results</b-link>

    <p>Now you can do both with a Cash Box PrizeSaver Account... the credit union savings account with a chance to win £5,000 every month!</p>
    <p>Cash Box is giving our members the opportunity to join other credit unions across the UK to be in with a chance to win cash prizes every month.</p>
    <p class="font-weight-bold">THIS IS A FREE PRIZE DRAW TO ENCOURAGE NEW AND EXISTING MEMBERS TO SAVE</p>
    <p>For every pound you have in a Cash Box PrizeSaver Account, you get a free entry into the monthly prize draw, up to a <strong>maximum of 200 entries</strong>.</p>
    <p>£1 in savings in a PrizeSaver Account is equivalent to 1 entry.</p>
    <p>We <strong>do not</strong> use your savings to enter you into the draw, your savings stay in your account.</p>
    <p>The PrizeSaver account is available for active Cash Box members over the age of 18.</p>

    <h2>How to open a PrizeSaver Account</h2>
    <b-card-group deck>
      <b-card
          class="mb-3"
          title="New Members"
      >
        <p>Join Cash Box Credit Union and start to save!  Once you have an active account with Cash Box you are eligible for all our savings products.</p>
        <a class="btn btn-success" href="https://cashbox.cuaccount.com/join/" target="_blank">Join Cash Box</a>
      </b-card>
      <b-card
          class="mb-3"
          title="Existing Members"
      >
        <p>If you wish to open a PrizeSaver Account in addition to your other Cash Box Savings Accounts, please log on and send us an
          instant message via the members area or send an email to <a href="mailto:enquiries@cashbox.org.uk">enquiries@cashbox.org.uk</a>.</p>
        <a href="https://cashbox.cuaccount.com/login/" target="_blank" class="btn btn-primary">Members Area</a>
      </b-card>
    </b-card-group>

    <h3>Account Features</h3>
    <ul>
      <li>Minimum balance £1.00</li>
      <li>Online account management</li>
      <li>FSCS Protection up to £85,000</li>
      <li>Deposit by Payroll, Standing Order or Child Benefit Payment</li>
      <li>Every £1, up to £200 is a chance to win up to £5000 each month</li>
    </ul>

    <p>
      <a href="/savings/prize-saver/terms-and-conditions" target="_blank">Prize Saver Terms & Conditions</a>,
      <a href="/savings/prize-saver/privacy-notice" target="_blank">Prize Saver Privacy Notice</a>.
    </p>

  </div>
</template>

<script>
export default {
  name: 'PrizeSaver',
  metaInfo() {
    return {
      title: 'Prize Saver | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "prize saver, prize, saver, cashbox, credit union, credit, union" },
        { name: 'description', content: "The credit union savings account with a chance to win £5,000 every month!" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
